var presentAfterpay = presentAfterpay || {};
var prodcat = prodcat || {};

(function ($) {

  var amount;
  // creating a select box handler here to allow triggering on pageload / change to populate pricing, etc. 

  function handleSkuSelect (el) {
    let skuId = $(el).val();
    let skuBaseId = skuId - 0;
    let sku = prodcat.data.getSku(skuBaseId);
    amount = sku.PRICE;
    let priceContainer = $('.js-sku-price');

    amount = amount * 100;
    $(el).closest('.js-product').trigger('product.skuSelect',[skuBaseId]);
    if (priceContainer && amount) {
      priceContainer.html(sku.formattedPrice);
    }
  }

  $(document).on('change', 'select.js-sku-menu', function(){
    handleSkuSelect(this);
    if (Drupal.settings?.common?.display_installment_price) {
      if (typeof presentAfterpay?.afterpayConfig === 'function') {
        presentAfterpay.afterpayConfig(amount);
      }
    }
  });

  $(document).ready(function(){
    $('select.js-sku-menu').each(function () {
      handleSkuSelect(this);
    });
  });


  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var $productAccordions = $product.find('.js-product-full__accordion');
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);

    $product.find('select.js-sku-menu').val(skuBaseId);
    $product.find('select.js-sku-menu.selectBox-attached').selectBox('value', skuBaseId);

    // Scroll page after sku selection
    var $image = $product.find('.product-full__image');

    if ($product.hasClass('product-full') && $image.length && $image.position()) {
      $('html, body').animate({ scrollTop: $image.position().top });
    }

    /* ------- Quickshop Product name & subline ------- */
    var $nameLink = $product.find('.js-product-name-link');

    if ($nameLink.length) {
      nameText = sku ? sku.SHADENAME : prod ? prod.PROD_RGN_NAME : '';
      $nameLink.html(nameText);
    }

    var $sublineLink = $product.find('.js-product-subline-link');

    if ($sublineLink.length) {
      var subLineText = sku ? sku.SKU_APPLICATION : prod ? prod.PROD_RGN_SUBHEADING : '';
      var bytext = site && site.translations && site.translations.product ? site.translations.product.by + ' ' : '';
      var text = Drupal.settings.common.show_by_text ? bytext : '';

      $sublineLink.html(text + subLineText);
    }

    $product.trigger('product.skuSelectUpdateImage', skuBaseId);

    /* ------- Sku Prices ------- */
    var product_sku_price = site.template.get({
      name: 'product_sku_price',
      data: sku // Only pare the sku because if sku is 0 then the price will be rendered empty
    });

    $product.find('.product_sku_price').html(product_sku_price);

    /* ------- Sku Accordion ------- */
    var productAccordionArea = site.template.get({
      name: 'product_accordion_area',
      data: document.querySelector('.js-sku-menu.selectBox') && sku && (sku.SHORT_DESC || sku.DESCRIPTION || sku.ATTRIBUTE_DESC_1) ? sku : prod // Check if there is a select box in the sku menu and enable sku descriptions, otherwise default to prod description
    });

    if ($productAccordions.length > 0 &&  parseInt($productAccordions.data('skuBaseId')) !== parseInt(skuBaseId)) {
      $productAccordions.data('skuBaseId', skuBaseId).attr('data-sku-base-id', skuBaseId);
      $('.product-full__accordion', $product)
        .fadeTo('slow', 0, 'linear', function () {
          $(this).html($(productAccordionArea).html());
          // Refresh the accordion if there is one
          Drupal.behaviors.productAccordionArea.attach();
        })
        .fadeTo('slow', 1, 'linear');
    }
    /* ------- Complete The Collection ------- */
    if ($product.hasClass('product-full')) {
      // Only run on SPP route update
      $(document).trigger('complete_the_collection_update', sku);
    }
  });

  $(document).on('product.skuSelectUpdateImage', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var $imageCarousel = $product.find('.js-product-full__image-carousel');
    if (parseInt(skuBaseId) === parseInt($imageCarousel.data('skuBaseId'))) {
      // don't change html of image gallery on page load.
      return;
    }
    $imageCarousel.data('skuBaseId', skuBaseId).attr('data-sku-base-id', skuBaseId);
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);
    /* ------- Sku Images ------- */
    var product_full_image = site.template.get({
      name: 'product_full_image',
      data: sku ? sku : prod && prod.defaultSku ? prod.defaultSku : prod // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    });

    $('.product-full__image', $product)
      .fadeTo('slow', 0, 'linear', function () {
        $(this).html($(product_full_image).html());
        // Refresh the slick slider if there is one
        $product.trigger('product.image.reinit');
      })
      .fadeTo('slow', 1, 'linear');
  });
})(jQuery);
